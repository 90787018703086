<template>
    <section class="cotizaciones-vigentes container-fluid overflow-auto custom-scroll my-2">
        <titulo-divisor titulo="Cotizaciones">
            <div class="row">
                <div class="col auto">
                    <el-input v-model="filters.buscar" placeholder="Buscar proveedores" size="small" @keypress.native.enter="listarCotizacionesVigentes"/>
                </div>
                <filter-indicator :count="countFilter" @openModalFilter="abrirModalFiltros" @clearFilter="limpiarFiltro"/>
            </div>
        </titulo-divisor>
        <tabla-general :usarServidor="true" :usarPaginacion="true" :servidorData="cotizacionesVigentes" :mostrarBuscador="false" >
            <el-table-column label="Cotización" prop="cotizacion" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 tres-puntos cr-pointer" @click="verCotizacion(scope.row.id)">
                        {{ scope.row.referencia }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha de solicitud" prop="fechaSolicitud" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.fecha_solicitud | helper-fecha('DD MMM YYYY') }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Materiales a cotizar" prop="cantMateriales" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.materiales_cotizar }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Estado" prop="estado" align="center">
                <template slot-scope="scope">
                    <div class="d-middle-center">
                        <i class="f-18" :class="scope.row.estado == 'Habilitado' ? 'icon-file-document-edit-outline text-naranja' : 'icon-progress-pencil'" />
                        <p class="f-12 text-muted2">
                            {{ scope.row.estado  }}
                        </p>
                    </div>
                </template>
            </el-table-column>
        </tabla-general>
         <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 my-3">
                    <p class="input-label-top">Estado</p>
                    <el-select v-model="filter.estado" placeholder="Seleccionar un estado" size="small" class="w-100">
                        <el-option v-for="item in estados" :key="item.id" :label="item.estado" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-1">
                    <p class="input-label-top">Rango fecha de solicitud</p>
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Desde</p>
                    <el-date-picker v-model="filter.fecha_inicio" type="date" value-format="yyyy-MM-dd" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Hasta</p>
                    <el-date-picker v-model="filter.fecha_fin" type="date" value-format="yyyy-MM-dd" :min="2022-11-28" placeholder="Seleccionar un día" size="small" class="w-100" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="listarCotizacionesVigentes"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    data(){
        return{
            buscarListado:'',
            options: [
                {
                    value: 17,
                    label: 'Borrador'
                },
                {
                    value: 18,
                    label: 'Habilitado'
                }
            ],
            cantFiltros: 0,
            valueSelect: '',
            estadoFilter: '',
            pickerFecha: '',
        }
    },
    computed:{
        ...mapGetters({
            cotizacionesVigentes: 'cotizacion/cotizacionesVigentes/getCotizacionesVigentes',
            estados: 'cotizacion/cotizacionesVigentes/estados',
            filter: 'cotizacion/cotizacionesVigentes/filter'
        }),
        filters: {
            get(){ return this.filter },
            set(val){ this.set_filter(val) }
        },
        query: {
            get(){ return this.filter.buscar },
            set(val){ this.set_filter(val) }
        },
        countFilter(){
            return this.filter.count()
        }
    },
    async created(){
      await this.listarCotizacionesVigentes()
      await this.Action_get_estados()
    },
    // beforeMount () {
    //     console.log(this.filter);
    // },
    methods:{
        ...mapMutations({
            set_filter: 'cotizacion/cotizacionesVigentes/set_filter',
        }),
        ...mapActions({
            Action_get_cotizaciones: 'cotizacion/cotizacionesVigentes/Action_get_cotizaciones',
            Action_clear_filter: 'cotizacion/cotizacionesVigentes/Action_clear_filter',
            Action_get_estados: 'cotizacion/cotizacionesVigentes/Action_get_estados',
        }),
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async limpiarFiltro(){
            this.cantFiltros = 0
            this.Action_clear_filter()
            await this.listarCotizacionesVigentes()
        },
        async listarCotizacionesVigentes(){
            await this.Action_get_cotizaciones()
        },
        verCotizacion(id_cotizacion){
            this.$router.push({
                name: 'cotizaciones.ver',
                params: {
                    id_cotizacion: id_cotizacion
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.cotizaciones-vigentes{
    height: calc(100vh - 245px);
}
</style>